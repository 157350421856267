var stackState = [];

export function pushState(state) {
    stackState.push(state);
}

export function popState() {
    return stackState.pop();
}

export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function dateTimeFormatter(date) {
    var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear(),
    hour = '' +d.getHours(),
    minute = '' +d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('.') + " " + hour + ":" + minute;
}

export function formatDateToGrid(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('.');
}

export function stringFormatter(cell, row) {
    if (checkIfTotalRow(row)) {
        return "<b>" + cell + "</b>";
    } else {
        return cell;
    }
}

export function priceFormatter(cell, row) {
    if (typeof cell == 'string') {
        return cell;
    }
    if (checkIfTotalRow(row)) {
        return "<b>" + (Math.round(cell * 100) / 100) + " €</b>";
    } else {
        return (Math.round(cell * 100) / 100) + " €";
    }
}

export function numberFormatter(cell, row) {
    if (typeof cell == 'string') {
        return cell;
    }
    if (checkIfTotalRow(row)) {
        return "<b>" + (Math.round(cell * 100) / 100) + "</b>";
    } else {
        return (Math.round(cell * 100) / 100);
    }
}

export function dateFormatter(cell, row) {
    if (checkIfTotalRow(row)) {
        return "<b>" + cell + "</b>";
    } else {
        return formatDateToGrid(new Date(cell));
    }
}

export function calculateTotals(rows) {
    if (rows.length == 0) {
        return rows;
    }
    var objSum = {};
    for (var index = 0; index < rows.length; index++) {
        
        for (var prop in rows[index]) {
            if (objSum[prop] == undefined) {
                objSum[prop] = 0;
            }
            if (typeof rows[index][prop] == 'number') {
                objSum[prop] += (Math.round(rows[index][prop] * 100) / 100);
            } else if (typeof rows[index][prop] == 'string') {
                objSum[prop] = 'SKUPAJ';
            }
        }

    }
    rows.push(objSum);   
    return rows
}

 export function  timeFormatter(seconds) {
    if (typeof seconds == 'string') {
        return seconds;
    }
    var sec_num = parseInt(seconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return hours+':'+minutes+':'+seconds;
}

export function dateToTicks (date) {
    var ticks = ((date.getTime() * 10000) + 621355968000000000);
    return ticks;
}


var HISTORY_LOGIN = "HISTORY_LOGIN"; 
var AUTH_DATA = "AUTH_DATA";

export function saveLoginData (el) {
    var data = getHistoryLoginData();
    for (var index = 0; index < data.length; index++) {
        var row = data[index];
        if (row.key == el.key) {
            data.splice(index, 1);
            break;
        }
    }
    data.push(el);
    localStorage.setItem(HISTORY_LOGIN, JSON.stringify(data));
}

export function saveAuthData(data) {
    localStorage.setItem(AUTH_DATA, JSON.stringify(data));
}

export function getAuthToken(data) {
    var data = localStorage.getItem(AUTH_DATA);
    if (data != null) {
        data = JSON.parse(data);
        return {
            headers: new Headers({
                'Authorization': 'Bearer ' + data.token,
            })
        };
    }
    return null;
}

export function removeAuthToken() {
    
}

export function getHistoryLoginData () {
    var data = localStorage.getItem(HISTORY_LOGIN);
    if (data == null) {
        data = [];
    } else {
        data = JSON.parse(data);
    }
    return data;
}

export function getLastLoginKey () {
    var data = localStorage.getItem(HISTORY_LOGIN);
    if (data == null) {
        return "";
    } else {
        data = JSON.parse(data);
        return data.pop().key;
    }
}

function checkIfTotalRow(row) {
    for (var prop in row) {
        if (typeof row[prop] == 'string') {
            return row[prop] == 'SKUPAJ';
        }
    }
    return false;
}
