import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import { clearUser } from "../js/actions/index";
import { Button } from "react-bootstrap";
import Loader from './MpLoader'
import { getAuthToken } from "../js/util/index";
import { removeAuthToken } from "../js/util/index";

const mapStateToProps = state => {
  return { users: state.users };
};

function mapDispatchToProps(dispatch) {
  return {
    clearUser: user => dispatch(clearUser(user))
  };
}

class ConnectedAuthorization extends Component {
  _mustLogin = false;

  constructor(props) {
    super(props);

    this.state = { isLoading : false };
    this._mustLogin = props.mustLogin;
    this.logout = this.logout.bind(this);
  }
  
  logout() {
    this.setState({ isLoading: true });
    var self = this;
      fetch('api/login/logout', getAuthToken())
    .then(function(response) {
      return response.json();
    }).then(function (data) {
        self.setState({ isLoading: false });
        if (data.success == true) {
            self.props.clearUser();
            removeAuthToken();
            self.props.history.push('/');
        } else {
            alert('Pri dostopu do strežnika je prišlo do napake. Prosim poskusite ponovno.');
        }
    }).catch(function (data) {
      self.setState({ isLoading: false });
      alert('Pri dostopu do strežnika je prišlo do napake. Prosim poskusite ponovno.');
    });
  }

  render() {
    if (this._mustLogin == true) {
      if (this.props.users.length != 1) {
        return <Redirect to='/' />;
      }
    } else {
      if (this.props.users.length == 1) {
        return <Redirect to='/Home' />;
      }
      return null;
    }

    return (
      <div>
        <Loader show={this.state.isLoading} />
        <Button
          className="btn btn-outline-white btn-block px-3"
          onClick={this.logout}>Odjava</Button>
      </div>
    );
  }
}

const Authorization = connect(mapStateToProps, mapDispatchToProps)(ConnectedAuthorization);
export default Authorization;