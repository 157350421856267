import React, { Component } from "react";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.lastYear = new Date().getFullYear();
  }
  
  render() {

    return (
      <div className="footer-wrapper">
          <div className="footer dark">
              <div className="row mx-0">
                  <div className="col  text-center">
                  2019 - {this.lastYear} © <a href="http://www.mikropos.si">SkupinaCOMP d.o.o.</a>, Vse pravice pridržane.
                  </div>
              </div>
          </div>
      </div>
    );
  }
}

export default Footer;