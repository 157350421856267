import React, { Component } from "react";
import Loader from 'react-loader-spinner'

class MpLoader extends Component {
  constructor(props) {
    super(props);

  }
  
  render() {
    var loaderStyle = {
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      position: 'fixed',
      display: 'block',
      opacity: 0.7,
      backgroundColor: '#fff',
      zIndex: 99999999999,
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      display: this.props.show == true ? 'block' : 'none'
    }

    return (
      <div style={loaderStyle}>
        <Loader 
            type="Bars"
            color="#00BFFF"
            height="100"	
            width="100"
        />  
      </div>
    );
  }
}

export default MpLoader;