import React, { Component } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { addUser } from "../js/actions/index";
import Authorization from './Authorization'
import Loader from './MpLoader'
import Footer from './Footer'
import { saveLoginData } from "../js/util/index";
import { saveAuthData } from "../js/util/index";
import { getAuthToken } from "../js/util/index";
import { getHistoryLoginData } from "../js/util/index";
import { getLastLoginKey } from "../js/util/index";

function mapDispatchToProps(dispatch) {
  return {
    addUser: user => dispatch(addUser(user))
  };
}

class ConnectedLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      password: "",
      key: getLastLoginKey(),
      message : "",
      isLoading : false
    };
    this.lastYear = new Date().getFullYear();
    this.login = this.login.bind(this);
    }

  validateForm() {
    return this.state.username.length > 0 && this.state.password.length > 0  && this.state.key.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  login () {
    const { username } = this.state;
    const { password } = this.state;
    const { key } = this.state;

    var reqObj = {
      username,
      password,
      key
      };

    this.setState({ isLoading: true });
    var self = this;
    fetch('api/login/authenticate', {
      method: 'post',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(reqObj)
    }).then(function(response) {
      return response.json();
    }).then(function (data) {
      self.setState({ isLoading: false });
      if (data.success == true) {
        self.setState({ username: "" });
        self.setState({ password: "" });
          self.setState({ key: "" });
          saveAuthData(data.data);
          self.checkMe();
      } else {
        self.setState({ message: "Vnesli ste napačno uporabniško ime, geslo ali ključ." });
      }
    }).catch(function (data) {
      self.setState({ isLoading: false });
      alert('Pri dostopu do strežnika je prišlo do napake. Prosim poskusite ponovno.');
    });
    }

    checkMe() {
        var authData = getAuthToken();
        if (authData != null) {
            this.setState({ isLoading: true });
            var self = this;
            fetch('api/login/me', authData)
                .then(function (response) {
                    return response.json();
                }).then(function (data) {
                    self.setState({ isLoading: false });
                    if (data.success == true) {
                        self.props.addUser(data.data);
                        saveLoginData({
                            header1: data.data.header1,
                            header2: data.data.header2,
                            header3: data.data.header3,
                            header4: data.data.header4,
                            header5: data.data.header5,
                            key: data.data.key
                        });
                        self.props.history.push('/Home');
                    }
                }).catch(function (data) {
                    self.setState({ isLoading: false });
                    alert('Pri dostopu do strežnika je prišlo do napake. Prosim poskusite ponovno.');
                });
        }
    }

  

  setKey(e, key) {
      this.setState({key});
  }

  render() {
    return (
      <div className="wrapper">
        <Loader show={this.state.isLoading} />
        <Authorization mustLogin={false} />
        <div className="page">
          <div className="page-content h-100">
            <div className="background">
              <img src="img/background.png" alt="" /> 
            </div>
            <div className="row mx-0">
              <div className="col">
                <img src="img/logoTitle.png" alt="" className="login-logo" />
                {/* <h1 className="login-title"><small>Dobrodošli, </small><br/>mikroPOS</h1> */}
              </div>
            </div>

            <div className="row mx-0">
              <div className="col">
                  <ul className="nav nav-tabs login-tabs mt-3" role="tablist">
                      <li className="nav-item">
                          <a className="nav-link border-white text-white show active" 
                          data-toggle="tab" 
                          href="#login"
                          role="tab" aria-selected="true">Prijavi se</a>
                      </li>
                  </ul>
                  <div className="tab-content">
                    <div className="tab-pane active" id="login" role="tabpanel">
                      <div className="login-input-content">
                        <FormGroup controlId="username" className="input-group" >
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">person</i>
                            </span>
                          </div>
                          <FormControl
                            autoFocus
                            type="username"
                            placeholder="Uporabniško ime" 
                            aria-label="Uporabniško ime"
                            value={this.state.username}
                            onChange={this.handleChange}
                          />
                        </FormGroup>

                        <FormGroup controlId="password" className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">lock</i>
                            </span>
                          </div>
                          <FormControl
                            value={this.state.password}
                            onChange={this.handleChange}
                            type="password"
                            placeholder="Geslo" 
                            aria-label="Geslo"
                          />
                        </FormGroup>
                        <FormGroup controlId="key" className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="material-icons">vpn_key</i>
                            </span>
                          </div>
                          <FormControl
                            value={this.state.key}
                            onChange={this.handleChange}
                            type="key"
                            placeholder="Ključ blagajne" 
                            aria-label="Ključ blagajne"
                          />
                        </FormGroup>
                      </div>
                      <div className="row mx-0 justify-content-end no-gutters">
                        <div className="col-2">
                          <a className="btn btn-block gradient border-0 z-3" 
                            href="javascript:void(0)" 
                            data-toggle="modal" 
                            data-target="#popupHistory"
                            >
                            <i className="material-icons">history</i>
                          </a>
                        </div>
                        <div className="col-4">
                            
                        </div>
                        <div className="col-6">
                          <Button
                            className="btn btn-block gradient border-0 z-3"
                            disabled={!this.validateForm()}
                            onClick={this.login}
                          >
                            Prijava
                          </Button>
                        </div>
                        <br/>
                        <br/>
                        <div className="col-12">
                            
                          <span className="text-danger">
                            <b>
                              {this.state.message}
                            </b>
                          </span>

                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <Footer />
          </div>
          
        </div>


        <div className="modal fade" id="popupHistory" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="background bg-225"><img src="img/background.png" alt="" /></div>
                    <div className="modal-header">
                        <h5 className="text-white">Zgodovina povezav</h5>
                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col my-3">
                                <a href="#" className="media">
                                  <div className="media-body align-self-center ">
                                      <h5 className="text-white">Prosim iz seznama izberite blagajno na katero se želite povezati.</h5>
                                  </div>
                                </a>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mt-3">
                                <div className="login-input-content">
                                  {
                                    getHistoryLoginData().length == 0 &&
                                    <h5>
                                      Vaš seznam je prazen.
                                    </h5>
                                  }
                                  <ul className="list-group list-group-flush">
                                      {getHistoryLoginData().map((value, index) => {

                                        return <li key={index} className="list-group-item">
                                                  <a className="btn btn-block btn-outline-secondary" 
                                                      href="javascript:void(0)"
                                                      data-dismiss="modal"
                                                      onClick={e => this.setKey(e, value.key)}
                                                  >
                                                    { value.header1 }<br/>
                                                    { value.header2 }<br/>
                                                    { value.header3 }<br/>
                                                    { value.header4 }<br/>
                                                    { value.header5 }
                                                  </a>
                                                </li>
                                      })}
                                  </ul>
                                </div>
                                <div className="row mx-0 justify-content-end no-gutters">
                                    <div className="col">
                                        <br/>
                                        <a href="javascript:void(0)" className="btn btn-block gradient border-0 z-3" data-dismiss="modal">Zapri</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

      </div>
    );
  }
}

const Login = connect(null, mapDispatchToProps)(ConnectedLogin);
export default Login;