import React from 'react';
import { Route, Switch, withRouter  } from "react-router-dom";
import Login from './Login'
import Home from './Home'
import About from './About'
import ReportByDate from './ReportByDate'
import ReportByItemGroups from './ReportByItemGroups'
import ReportByItems from './ReportByItems'
import ReportByUsers from './ReportByUsers'
import ReportByAmountToHourView from './ReportByAmountToHourView'
import ReportByDayHourView from './ReportByDayHourView'
import ReportStockByDate from './ReportStockByDate'
import ReportWorkingTime from './ReportWorkingTime'
import ReportByPayment from './ReportByPayment'
import ReportByKsAverageTime from './ReportByKsAverageTime'
import ReportByZReport from './ReportByZReport'
import DocumentZReport from './DocumentZReport'
import ReportByOpenTables from './ReportByOpenTables'
import TableDetail from './TableDetail'
import ChartByUsers from './ChartByUsers'
import ChartByItemGroups from './ChartByItemGroups'
import ChartByDate from './ChartByDate'

function Main() {
    return (
        <Switch>
            <Route exact path='/' component={withRouter(Login)}></Route>
            <Route exact path='/Home' component={withRouter(Home)}></Route>
            <Route exact path='/About' component={withRouter(About)}></Route>
            <Route exact path='/ReportByDate' component={withRouter(ReportByDate)}></Route>
            <Route exact path='/ReportByItemGroups' component={withRouter(ReportByItemGroups)}></Route>
            <Route exact path='/ReportByItems' component={withRouter(ReportByItems)}></Route>
            <Route exact path='/ReportByUsers' component={withRouter(ReportByUsers)}></Route>
            <Route exact path='/ReportByAmountToHourView' component={withRouter(ReportByAmountToHourView)}></Route>
            <Route exact path='/ReportByDayHourView' component={withRouter(ReportByDayHourView)}></Route>
            <Route exact path='/ReportStockByDate' component={withRouter(ReportStockByDate)}></Route>
            <Route exact path='/ReportWorkingTime' component={withRouter(ReportWorkingTime)}></Route>
            <Route exact path='/ReportByPayment' component={withRouter(ReportByPayment)}></Route>
            <Route exact path='/ReportByKsAverageTime' component={withRouter(ReportByKsAverageTime)}></Route>
            <Route exact path='/ReportByZReport' component={withRouter(ReportByZReport)}></Route>
            <Route exact path='/DocumentZReport' component={withRouter(DocumentZReport)}></Route>
            <Route exact path='/ReportByOpenTables' component={withRouter(ReportByOpenTables)}></Route>
            <Route exact path='/TableDetail' component={withRouter(TableDetail)}></Route>
            <Route exact path='/ChartByUsers' component={withRouter(ChartByUsers)}></Route>
            <Route exact path='/ChartByItemGroups' component={withRouter(ChartByItemGroups)}></Route>
            <Route exact path='/ChartByDate' component={withRouter(ChartByDate)}></Route>
        </Switch>
    );
  }
  
  export default Main;