import React, { Component } from "react";
import DateTimePicker from 'react-datetime-picker'
import Navigation from './Navigation'
import Footer from './Footer'
import Header from './Header'
import { Button } from "react-bootstrap";
import Loader from './MpLoader'
import { formatDate } from "../js/util/index";
import { priceFormatter } from "../js/util/index";
import { dateFormatter } from "../js/util/index";
import { stringFormatter } from "../js/util/index";
import { numberFormatter } from "../js/util/index";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { popState } from "../js/util/index";
import { pushState } from "../js/util/index";
import { getAuthToken } from "../js/util/index";

class ReportByOpenTables extends Component {
  constructor(props) {
    super(props);


    if ( props.history.action == "POP") {
      this.state = popState();
    } else {
      this.state = {
        isLoading : false,
        rows: []
      };
    }

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    if (this.state == null || this.state.rows == null || this.state.rows.length == 0) {
      this.loadData();
    }
  }

  loadData () {
    this.setState({ isLoading: true });
    var self = this;

      fetch('api/report/reportByOpenTables?', getAuthToken())
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({ isLoading: false });
      if (data.success == true) {
        self.setState({ rows: data.data });
      } else {
          alert('Napaka');
      }
    }).catch(function (data) {
      self.setState({ isLoading: false });
      alert('Pri dostopu do strežnika je prišlo do napake. Prosim poskusite ponovno.');
    });
  }

  openTable(el, tableId) {
    var table = null;
    for (var x = 0; x < this.state.rows.length; x++) {
      var row = this.state.rows[x];
      if (row.id == tableId) {
        table = row;
        break;
      }
    }
    if (table != null && table.orders.length > 0) {
      pushState(this.state);
      this.props.history.push({
        pathname: '/TableDetail',
        state: { table: table }
      });
    }
  }
  
  render() {
    if (this.state == null || this.state.rows == null) {
      return null;
    }
    var locations = [];

    for (var x = 0; x < this.state.rows.length; x++) {
      var row = this.state.rows[x];
      var found =  false;
      for(var i = 0; i < locations.length; i++) {
        if (locations[i].id == row.tableLocationId ) {
            found = true;
            break;
        }
      }

      if (found == false) {
        locations.push( {
            id: row.tableLocationId,
            title: row.tableLocationTitle 
        });
      }

    }
    return (
      <div  className="wrapper">
        <Loader show={this.state.isLoading} />
        <Navigation history={this.props.history} />
        <div className="page">
            
            <Header title="Odprte mize"/>

            <div className="page-content">
                <div className="content-sticky-footer">
                    
                  <div className="col-12">
                    <br/>
                    <div className="card rounded-0 border-0 mb-3 bg-primary">
                      <div className="card-body  text-white">
                          <div className="row">
                              <div className="col-12">
                              <Button
                                className="btn btn-block mb-1 btn-light text-primary px-4"
                                onClick={this.loadData}>
                                <i className="material-icons">refresh</i>
                              </Button>
                              </div>
                          </div>

                      </div>
                    </div>

                  </div>
                
                  <div className="col-12">
                    <div className="card mb-3 wizard">
                        <div className="card-header p-0">
                            <ul className="nav nav-tabs tabs-md nav-justified" role="tablist">
                              {locations.map((value, index) => {
                                return <li key={ index } className="nav-item" role="presentation">
                                    <a title={ value.title } 
                                      className={ index == 0 ? "nav-link border-primary active shown" : "nav-link border-primary" } 
                                      role="tab" 
                                      aria-selected={ index == 0 ? "true" : "false" } 
                                      aria-controls={ "tab_" + value.id }
                                      href={ "#tab_" + value.id } 
                                      data-toggle="tab">
                                      { value.title }
                                    </a>
                                </li>
                              })}
                            </ul>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">

                              {locations.map((value, index) => {
                            
                                return <div key={ index } className={ index == 0 ? "tab-pane active shown" : "tab-pane" } 
                                    id={ "tab_" + value.id } 
                                    role="tabpanel">

                                      <ul className="list-group list-group-flush">
                                        {this.state.rows.map((table, tableIndex) => {
                                          if (table.tableLocationId != value.id) {
                                            return null;
                                          }
                                          return <li key={tableIndex} className="list-group-item">
                                              <a className="d-block" data-id={ table.id } onClick={e => this.openTable(e, table.id)}  >
                                                  <figure className="avatar avatar-40 border">
                                                    <img alt="" src={table.orders.length == 0 ? "img/table_not_used.png" : "img/table.png" }/>
                                                  </figure>
                                                  {table.title}
                                                  <span className="badge badge-primary">{ table.orders.length }</span>
                                                  <span className="badge badge-success">{ table.maxRound }</span>
                                                  <span className="badge badge-primary">{ table.amount } €</span>
                                                  <span className="badge badge-warning">{ table.userTitle }</span>
                                              </a>
                                          </li>
                                        })}
                                      </ul>
                                    </div>
                                })}
                                <div className="clearfix"></div>
                            </div>
                        </div>

                    </div>
                  
                  </div>

                </div>
                <Footer/>
            </div>
        </div>
      </div>
    );
  }
}

export default ReportByOpenTables;