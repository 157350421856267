import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from 'react-router-dom';
import Authorization from './Authorization'
import UserInfo from './UserInfo'

const $ = window.$;

const mapStateToProps = state => {
    return { users: state.users };
  };

class ConnectedNavigation extends Component{
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        $('body').removeClass('menu-left-open');
        $('.backdrop').remove();

        $('.menu-left').on('click', function () {
            $('body').addClass('menu-left-open');
            $('body .wrapper').append('<div class="backdrop"></div>');
            $('.backdrop').on('click', function () {
            $('body').removeClass('menu-left-open');
            $('.backdrop').fadeOut().remove();
            });
        });

        $('.sidebar-close').on('click', function () {
            $('body').removeClass('menu-left-open');
            $('.backdrop').fadeOut().remove();
        });
    }

    render() {
        return (
            <div className="sidebar sidebar-left">
                <UserInfo/>
                <nav className="navbar">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/Home'>
                                <div className="item-title">
                                    <i className="material-icons">filter_none</i>Domov
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByDate'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Poročilo po dnevih
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ChartByDate'>
                                <div className="item-title">
                                    <i className="material-icons">multiline_chart</i>Graf po dnevih
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByItemGroups'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Poročila po skupinah artiklov
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ChartByItemGroups'>
                                <div className="item-title">
                                    <i className="material-icons">pie_chart</i>Graf po skupinah artiklov
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByItems'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Poročilo po artiklih
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByUsers'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Poročilo po uporabnikih
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ChartByUsers'>
                                <div className="item-title">
                                    <i className="material-icons">pie_chart</i>Graf po uporabnikih
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByDayHourView'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Poročilo po dnevih in urah
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByPayment'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Poročilo po načinih plačila po dnevih
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByAmountToHourView'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Poročilo prometa po urah
                                </div>
                            </NavLink>
                        </li>

                        { this.props.users != null && this.props.users.length == 1 &&
                          this.props.users[0].isStock == true &&
                            <li className="nav-item">
                                <NavLink className="sidebar-close" to='/ReportStockByDate'>
                                    <div className="item-title">
                                        <i className="material-icons">poll</i>Poročilo zaloga na dan
                                    </div>
                                </NavLink>
                            </li>
                        }

                        { this.props.users != null && this.props.users.length == 1 &&
                          this.props.users[0].isWorkingTime == true &&
                            <li className="nav-item">
                                <NavLink className="sidebar-close" to='/ReportWorkingTime'>
                                    <div className="item-title">
                                        <i className="material-icons">poll</i>Poročilo delovnega časa
                                    </div>
                                </NavLink>
                            </li>
                        }
                        
                        { this.props.users != null && this.props.users.length == 1 &&
                          this.props.users[0].isKitchenScreen == true &&
                            <li className="nav-item">
                                <NavLink className="sidebar-close" to='/ReportByKsAverageTime'>
                                    <div className="item-title">
                                        <i className="material-icons">poll</i>Povprečen čas izdaje po urah
                                    </div>
                                </NavLink>
                            </li>
                        }
                        
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByOpenTables'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Odprte mize
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/ReportByZReport'>
                                <div className="item-title">
                                    <i className="material-icons">poll</i>Zaključki dneva
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="sidebar-close" to='/About'>
                                <div className="item-title">
                                    <i className="material-icons">domain</i>O nas
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
                <div className="profile-link text-center">
                    <Authorization mustLogin={true} history={this.props.history} />
                </div>
            </div>
        );
    };
  }
  
const Navigation = connect(mapStateToProps, null)(ConnectedNavigation);
export default Navigation;