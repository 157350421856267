import React, { Component } from "react";
import Navigation from './Navigation'
import Footer from './Footer'
import Header from './Header'
import { connect } from "react-redux";


const mapStateToProps = state => {
  return { users: state.users };
};

class ConnectedHome extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {

    return (
      <div  className="wrapper">
        <Navigation history={this.props.history} />
        <div className="page">
            
            <Header title="Domov"/>

            <div className="page-content">
                <div className="content-sticky-footer">
                    
                    <div className="row mx-0">
                      <div className="col">
                        <h1 className="block-title">Dobrodošli v mikroPOS</h1>
                      </div>
                    </div>

                    <div className="row mx-0 ">
                        <div className="col">
                            <p>Prijavljeni ste v spletno aplikacijo za spremljanje analize in poročil mikroPOS blagajne:</p>
                        </div>
                    </div>

                    <div className="login-input-content p-3">
                      <div className="form-group">
                          <label><b>{this.props.users != null && this.props.users.length > 0 ? this.props.users[0].header1 : "" }</b></label>
                          <br/>
                          <label>{ this.props.users != null && this.props.users.length > 0 ? this.props.users[0].header2 : "" }</label>
                          <br/>
                          <label>{ this.props.users != null && this.props.users.length > 0 ? this.props.users[0].header3 : "" }</label>
                          <br/>
                          <label>{ this.props.users != null && this.props.users.length > 0 ? this.props.users[0].header4 : "" }</label>
                          <br/>
                          <label>{ this.props.users != null && this.props.users.length > 0 ? this.props.users[0].header5 : "" }</label>
                      </div>
                    </div>
                    <br/>
                    <div className="login-input-content p-3">
                      <div className="form-group">
                          <label>Prijavljen uporabnik: <b>{ this.props.users != null && this.props.users.length > 0 ? this.props.users[0].username : "" }</b></label>
                      </div>
                    </div>

                </div>
                <Footer/>
            </div>
        </div>
      </div>
    );
  }
}

const Home = connect(mapStateToProps)(ConnectedHome);
export default Home;