import React, { Component } from "react";
import Navigation from './Navigation'
import Footer from './Footer'
import HeaderBack from './HeaderBack'
import Loader from './MpLoader'
import { priceFormatter } from "../js/util/index";
import { dateFormatter } from "../js/util/index";
import { numberFormatter } from "../js/util/index";
import { dateTimeFormatter } from "../js/util/index";
import { getAuthToken } from "../js/util/index";

class DocumentZReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.location.state.id, 
      title: "Z por.: " + props.location.state.talkingNumber,
      isLoading : false,
      data: {
        endDocumentStartEnds: [],
        endPayments: [],
        endVatRates: [],
        endDocuments: []
      }
    };

    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData () {
    this.setState({ isLoading: true });
    var self = this;
      fetch('api/report/DocumentZReport?id=' + this.state.id, getAuthToken())
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({ isLoading: false });
      if (data.success == true) {
        self.setState({ data: data.data });
      } else {
          alert('Napaka');
      }
    }).catch(function (data) {
      self.setState({ isLoading: false });
      alert('Pri dostopu do strežnika je prišlo do napake. Prosim poskusite ponovno.');
    });
  }

  back () {
    window.history.back();
  }
  
  render() {
    var sumCountPayments = 0;
    var sumCountVats = 0;
    var totalInCashier = this.state.data.totalPaymentTypes + this.state.data.cashInCashier;

    return (
      <div  className="wrapper">
        <Loader show={this.state.isLoading} />
        <Navigation history={this.props.history} />
        <div className="page">
            
            <HeaderBack title={ this.state.title } onBack={ this.back }/>

            <div className="page-content">
                <div className="content-sticky-footer">
                  <h2>Z-POROČILO</h2>
                  <h5>{ this.state.data.talkingNumber }</h5>
                  <h5>Promet na dan: { dateFormatter(this.state.data.dateOfEnd) }</h5>
                  <h5>Zaključeno: { dateTimeFormatter(this.state.data.dateOfEnd) }</h5>
                  <br/>
                  <h5>Upoštevani dokumenti</h5>

                  {this.state.data.endDocumentStartEnds.map((value, index) => {
                    return <h5>Računi {value.documentStart} do {value.documentEnd}</h5>
                  })}
                  <br/>
                  <br/>
                  <h5>POROČILO PO NAČINIH PLAČIL</h5>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                    <tr>
                      <th><h5><b>Naziv</b></h5></th>
                      <th ><h5><b>Količina</b></h5></th>
                      <th ><h5><b>Vrednost</b></h5></th>
                    </tr>
                    {this.state.data.endPayments.map((value, index) => {
                      if ( value.count > 0) {
                        sumCountPayments +=  value.count;
                        return <tr>
                          <td><h5>{ value.paymentType }</h5></td>
                          <td><h5>{ numberFormatter(value.count) }</h5></td>
                          <td><h5>{ priceFormatter(value.amount) }</h5></td>
                        </tr>
                      }
                      return null;
                  })}
                    <tr>
                      <td><h5><b>SKUPAJ</b></h5></td>
                      <td><h5><b>{ numberFormatter(sumCountPayments) }</b></h5></td>
                      <td><h5><b>{ priceFormatter(this.state.data.totalPaymentTypes) }</b></h5></td>
                    </tr>
                    </tbody>
                  </table>

                  <br/>
                  <br/>
                  <h5>POROČILO PO DAVKIH</h5>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                    <tr>
                      <th><h5><b>Naziv</b></h5></th>
                      <th ><h5><b>Osnova</b></h5></th>
                      <th ><h5><b>Davek</b></h5></th>
                      <th ><h5><b>Skupaj</b></h5></th>
                    </tr>
                    {this.state.data.endVatRates.map((value, index) => {
                      sumCountVats += value.count;
                      return <tr>
                        <td><h5>{ value.vatRate }</h5></td>
                        <td><h5>{ priceFormatter(value.amountBase) }</h5></td>
                        <td><h5>{ priceFormatter(value.amountVat) }</h5></td>
                        <td><h5>{ priceFormatter(value.amountVat + value.amountBase) }</h5></td>
                      </tr>
                  })}
                    <tr>
                      <td><h5><b>SKUPAJ</b></h5></td>
                      <td></td>
                      <td></td>
                      <td><h5><b>{ priceFormatter(this.state.data.totalVatTypes) }</b></h5></td>
                    </tr>
                    </tbody>
                  </table>
                  <br/>
                  <br/>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                    <tr>
                      <td><h5>Gotovina v blagajni</h5></td>
                      <td><h5>{priceFormatter(this.state.data.cashInCashier)}</h5></td>
                    </tr>
                    </tbody>
                  </table>
                  <h5>Izdatki in prejemki</h5>
                  <table className="table table-striped table-bordered table-hover">
                    <tbody>
                    <tr>
                      <td><h5><b>Naziv</b></h5></td>
                      <td><h5><b>Plačilo</b></h5></td>
                      <td><h5><b>Vrednost</b></h5></td>
                    </tr>
                    {this.state.data.endDocuments.map((value, index) => {
                        totalInCashier += value.amountOfPayment;
                        
                        return <tr>
                          <td><h5>{ value.noteAccount }</h5></td>
                          <td><h5>{ value.paymentTitle  }</h5></td>
                          <td><h5>{ priceFormatter(value.amountOfPayment ) }</h5></td>
                        </tr>
                    })}
                    </tbody>
                  </table>
                  <h3>SKUPAJ V BLAGAJNI: { priceFormatter(totalInCashier) }</h3>
                </div>
                <Footer/>
            </div>
        </div>
      </div>
    );
  }
}

export default DocumentZReport;