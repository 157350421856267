import React, { Component } from "react";
import DateTimePicker from 'react-datetime-picker'
import Navigation from './Navigation'
import Footer from './Footer'
import Header from './Header'
import { Button } from "react-bootstrap";
import Loader from './MpLoader'
import { formatDate } from "../js/util/index";
import { priceFormatter } from "../js/util/index";
import { dateFormatter } from "../js/util/index";
import { formatDateToGrid } from "../js/util/index";
import { dateToTicks } from "../js/util/index";
import PopupFilterQuick from './PopupFilterQuick'
import { getAuthToken } from "../js/util/index";

class ReportByPayment extends Component {
  constructor(props) {
    super(props);

    var dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7);
    
    this.state = {
      dateFrom: dateFrom,
      dateTo: new Date(),
      isLoading : false,
      rows: []
    };

    this.loadData = this.loadData.bind(this);
    this.dateFromOnChange = this.dateFromOnChange.bind(this);
    this.dateToOnChange = this.dateToOnChange.bind(this);
  }

  dateFromOnChange(dateFrom, force) {
    if (force == true || dateToTicks(dateFrom) <=  dateToTicks(this.state.dateTo)) {
      this.setState({ dateFrom });
    }
  }

  dateToOnChange(dateTo, force) {
    if (force == true || dateToTicks(this.state.dateFrom) <=  dateToTicks(dateTo)) {
      this.setState({ dateTo });
    }
  }
  
  componentDidMount() {
    this.loadData();
  }

  loadData () {
    this.setState({ isLoading: true });
    var self = this;

      fetch('api/report/reportByPayment?dateFrom=' + encodeURIComponent(formatDate(this.state.dateFrom)) + '&dateTo=' + encodeURIComponent(formatDate(this.state.dateTo)), getAuthToken())
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({ isLoading: false });
      if (data.success == true) {
        self.setState({ rows: data.data });
      } else {
          alert('Napaka');
      }
    }).catch(function (data) {
      self.setState({ isLoading: false });
      alert('Pri dostopu do strežnika je prišlo do napake. Prosim poskusite ponovno.');
    });
  }
  
  render() {
    const options = {
      noDataText: 'Ni podatkov'
    };

    var date = null;
    var sum = 0;
    var total = 0;

    return (
      <div  className="wrapper">
        <Loader show={this.state.isLoading} />
        <Navigation history={this.props.history} />
        <div className="page">
            
            <Header title="Poročilo po načinih plačila"/>

            <div className="page-content">
                <div className="content-sticky-footer">
                    
                  <div className="col-12">
                    <br/>
                    <div className="card rounded-0 border-0 mb-3 bg-primary">
                      <div className="card-body  text-white">
                          <div className="row">
                              
                          <div className="col-9">
                              <span>{formatDateToGrid(this.state.dateFrom)}</span>
                              <span>-</span>
                              <span>{formatDateToGrid(this.state.dateTo)}</span>
                          </div>

                          <div className="col-3">
                            <a className="btn btn-block gradient border-0 z-3" 
                                href="javascript:void(0)" 
                                data-toggle="modal" 
                                data-target="#popupFilter"
                                >
                                <i className="material-icons">filter_list</i>
                            </a>
                          </div>
                            

                          </div>
                          
                      </div>
                    </div>

                  </div>
                
                  <div className="col-12">
                    
                  <div className="react-bs-container-header table-header-wrapper">
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th className="" data-is-only-head="false" title="Datum" >Datum<div></div></th>
                          <th className="" data-is-only-head="false" title="Plačilo" >Plačilo<div></div></th>
                          <th className="" data-is-only-head="false" title="Znesek" >Znesek<div></div></th>
                          
                        </tr>
                      </thead>
                    </table>
                  </div>

                <div className="react-bs-container-body" >
                  <table className="table table-striped table-bordered table-hover">
                        <tbody>
                        {this.state.rows.map((value, index) => {
                          var html;
                          if (date == null) {
                            date = new Date(value.id);
                          }

                          if (date.toString() != new Date(value.id).toString()) {
                            
                            html =<tr  className="">
                                <td className="" ><div></div></td>
                                <td className="" ><b>SKUPAJ</b><div></div></td>
                                <td className="" ><b>{ priceFormatter(sum) }</b><div></div></td>
                              </tr>;
                            sum = 0;
                            date = new Date(value.id);
                          }
                          sum += value.amount;
                          total += value.amount;
                          return <React.Fragment>
                                  {html}  
                                  <tr  className="">
                                    <td className="" >{ dateFormatter(value.id) }<div></div></td>
                                    <td className="" >{ value.title }<div></div></td>
                                    <td className="" >{ priceFormatter(value.amount) }<div></div></td>
                                  </tr>
                                  </React.Fragment>
                        })}
                            <tr  className="">
                              <td className="" ><div></div></td>
                              <td className="" ><b>SKUPAJ</b><div></div></td>
                              <td className="" ><b>{ priceFormatter(sum) }</b><div></div></td>
                            </tr>
                            <tr  className="">
                              <td className="" ><div></div></td>
                              <td className="" ><b>SKUPAJ VSE</b><div></div></td>
                              <td className="" ><b>{ priceFormatter(total) }</b><div></div></td>
                            </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
                <Footer/>
            </div>
        </div>
        <div className="modal fade" id="popupFilter" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="background bg-225"><img src="img/background.png" alt="" /></div>
                    <div className="modal-header">
                        <h5 className="text-white">Filter</h5>
                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                        <div className="row">
                            <div className="col mt-3">
                                <div className="login-input-content">

                                <div className="col-12">
                                  <br/>
                          
                                  <div className="col-12">
                                      <label>Datum od</label>
                                  </div>
                                  <div className="col-12">
                                      <div className="form-group">
                                        <DateTimePicker 
                                        className="form-control  text-white" 
                                        format="dd.MM.y" 
                                        onChange={this.dateFromOnChange}
                                        value={this.state.dateFrom}
                                        />
                                      </div>
                                  </div>
                                  <div className="col-12">
                                      <label>Datum do</label>
                                  </div>
                                  <div className="col-12">
                                      <div className="form-group">
                                        <DateTimePicker 
                                        className="form-control  text-white" 
                                        format="dd.MM.y" 
                                        onChange={this.dateToOnChange}
                                        value={this.state.dateTo}
                                        />
                                      </div>
                                  </div>
                                  <br/>
                                </div>

                                </div>
                                <div className="row mx-0 justify-content-end no-gutters">
                                    <div className="col">
                                        <br/>
                                        <a href="javascript:void(0)" onClick={this.loadData} className="btn btn-block gradient border-0 z-3" data-dismiss="modal">
                                          <i className="material-icons">search</i>
                                        </a>
                                    </div>
                                </div>
                                <br/>
                                
                                <PopupFilterQuick dateFromOnChange={this.dateFromOnChange} 
                                  dateToOnChange={this.dateToOnChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default ReportByPayment;