import React, { Component } from "react";

class Header extends Component {
  constructor(props) {
    super(props);
    this.title = "mikroPOS";
    if (this.props.title != null && this.props.title != undefined && this.props.title.length > 0) {
      this.title = this.props.title
    }
  }
  
  render() {

    return (
      <header className="row m-0 fixed-header">
          <div className="left">
          <a href="javascript:void(0)" onClick={this.props.onBack}><i className="material-icons">keyboard_backspace</i></a>
          </div>
          <div className="col center">
              <div className="logo">
                <figure><img src="img/logo.png" alt="" /></figure>{this.title}
              </div>
          </div>
      </header>
    );
  }
}

export default Header;