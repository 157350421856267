import React, { Component } from "react";
import { connect } from "react-redux";
import { clearUser } from "../js/actions/index";

const mapStateToProps = state => {
  return { users: state.users };
};

function mapDispatchToProps(dispatch) {
  return {
    clearUser: user => dispatch(clearUser(user))
  };
}

class ConnectedUserInfo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
   
      if (this.props.users.length == 1) {
        return(
          <div className="profile-link">
              <div className="media">
                  <div className="w-auto h-100">
                  </div>
                  <div className="media-body">
                      <h5><i className="material-icons">person</i> {this.props.users[0].username}</h5>
                      <p>{this.props.users[0].header1}</p>
                      <p>{this.props.users[0].header2}</p>
                      <p>{this.props.users[0].header3}</p>
                      <p>{this.props.users[0].header4}</p>
                      <p>{this.props.users[0].header5}</p>
                  </div>
              </div>
          </div>
        );
      } else {
        return null;
      }
  }
}

const UserInfo = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserInfo);
export default UserInfo;