import React, { Component } from "react";
import Navigation from './Navigation'
import Footer from './Footer'
import HeaderBack from './HeaderBack'
import Loader from './MpLoader'
import { priceFormatter } from "../js/util/index";
import { numberFormatter } from "../js/util/index";
import { stringFormatter } from "../js/util/index";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';

class TableDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.location.state.id, 
      table: props.location.state.table,
      isLoading : false
    };

  }

  back () {
    window.history.back();
  }
  
  render() {
    const options = {
      noDataText: 'Ni podatkov'
    };

    return (
      <div  className="wrapper">
        <Loader show={this.state.isLoading} />
        <Navigation history={this.props.history} />
        <div className="page">
            
            <HeaderBack title={ this.state.table.title } onBack={ this.back }/>

            <div className="page-content">
                <div className="content-sticky-footer">
                  
                  <div className="col-12">
                      <h2 className="block-title" >Ime: { this.state.table.title }</h2>
                      <h2 className="block-title" >Skupaj: { priceFormatter(this.state.table.amount) }</h2>
                  </div>

                  <div className="col-12">
                    <BootstrapTable data={this.state.table.orders} version='4' options={ options } striped hover>
                      <TableHeaderColumn width='150' isKey={ true } dataField='round' dataFormat={ numberFormatter }>Runda</TableHeaderColumn>
                      <TableHeaderColumn width='150' dataField='title' dataFormat={ stringFormatter }>Artikel</TableHeaderColumn>
                      <TableHeaderColumn width='150' dataField='quantity' dataFormat={ numberFormatter }>Kol.</TableHeaderColumn>
                      <TableHeaderColumn width='150' dataField='userTitle' dataFormat={ stringFormatter }>Uporabnik</TableHeaderColumn>
                      <TableHeaderColumn width='150' dataField='amountWithVat' dataFormat={ priceFormatter }>Znesek</TableHeaderColumn>
                    </BootstrapTable>
                  </div>
                  
                </div>
                <Footer/>
            </div>
        </div>
      </div>
    );
  }
}

export default TableDetail;