import React, { Component } from "react";
import { Button, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import Navigation from './Navigation'
import Footer from './Footer'
import Header from './Header'

class About extends Component {
  constructor(props) {
    super(props);
  }
  
  render() {

    return (
      <div  className="wrapper">
        <Navigation history={this.props.history} />
        <div className="page">
            
            <Header title="O nas"/>

            <div className="page-content">
                
                <div className="spb_content_element col-sm-9 spb_text_column">
                  <div className="spb-asset-content" >
                    <h2 className="h1">Specialisti za davčne blagajne</h2>
                  </div>
                </div> 

                <div className="spb_content_element col-sm-9 spb_text_column">
                  <div className="spb-asset-content" >
                    <p>Podjetje, specializirano za davčne blagajne na področju gostinstva in trgovine. Naše stranke so vedno na prvem mestu in naša skrb je, da se tako tudi počutijo. Pokličite nas in se prepričajte.</p>
                  </div>
                </div> 

                <div className="spb_content_element col-sm-9 small spb_text_column">
                  <div className="spb-asset-content" >
                    <p><strong>Naslov:</strong><br/>
                    skupinaCOMP d.o.o.<br/>
                    Savska cesta 34,<br/>
                    4000 Kranj</p>
                    <p><strong>E-poštni naslov:</strong><br/>
                    podpora@mikroPOS.si</p>
                    <p><strong>ID za DDV:</strong>SI 40523055<br/>
                        <strong>TRR NLB:</strong>SI56 0206 8026 0597 227<br />
                    </p>
                  </div>
                </div> 
                
            </div>
            <Footer/>
        </div>
      </div>
    );
  }
}

export default About;