import React, { Component } from "react";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import { connect } from "react-redux";
import { addUser } from "../js/actions/index";
import Authorization from './Authorization'
import Loader from './MpLoader'
import Footer from './Footer'
import { saveLoginData } from "../js/util/index";
import { getHistoryLoginData } from "../js/util/index";
import { getLastLoginKey } from "../js/util/index";



class PopupFilterQuick extends Component {
  constructor(props) {
    super(props);

    this.today = this.today.bind(this);
    this.yesterday = this.yesterday.bind(this);
    this.last7days = this.last7days.bind(this);
    this.lastMonth = this.lastMonth.bind(this);
  }

  today() {
    var dateFrom = new Date();
    var dateto = new Date();
    
    this.props.dateFromOnChange(dateFrom, true);
    this.props.dateToOnChange(dateto, true);
  }

  yesterday() {
    var dateFrom = new Date();
    var dateto = new Date();
    dateFrom.setDate(dateFrom.getDate() - 1);
    dateto.setDate(dateto.getDate() - 1);
    
    this.props.dateFromOnChange(dateFrom, true);
    this.props.dateToOnChange(dateto, true);
  }

  last7days() {
    var dateFrom = new Date();
    var dateto = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7);
    
    this.props.dateFromOnChange(dateFrom, true);
    this.props.dateToOnChange(dateto, true);
  }

  lastMonth() {
    var dateFrom = new Date();
    var dateto = new Date();

    dateFrom = new Date(dateFrom.getFullYear(), dateFrom.getMonth(), 1);
    
    this.props.dateFromOnChange(dateFrom, true);
    this.props.dateToOnChange(dateto, true);
  }

  render() {
    return (
      <div className="row">
        <div className="col-6">
          <Button className="btn btn-outline-white btn-block" onClick={this.today}>
            Danes
          </Button>
        </div>
        <div className="col-6">
          <Button className="btn btn-outline-white btn-block" onClick={this.yesterday}>
            Včeraj
          </Button>
        </div>
        <br/>
        <br/>
        <div className="col-6">
          <Button className="btn btn-outline-white btn-block " onClick={this.last7days}>
            Zadnje 7 dni
          </Button>
        </div>
        <div className="col-6">
            <Button className="btn btn-outline-white btn-block" onClick={this.lastMonth}>
              Zadnji mesec
            </Button>
        </div>
      </div>
    );
  }
}

export default PopupFilterQuick;