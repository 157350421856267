import React, { Component } from "react";
import DateTimePicker from 'react-datetime-picker'
import Navigation from './Navigation'
import Footer from './Footer'
import Header from './Header'
import { Button, FormGroup, FormControl } from "react-bootstrap";
import Loader from './MpLoader'
import { formatDate } from "../js/util/index";
import { numberFormatter } from "../js/util/index";
import { calculateTotals } from "../js/util/index";
import { stringFormatter } from "../js/util/index";
import { formatDateToGrid } from "../js/util/index";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { getAuthToken } from "../js/util/index";

class ReportStockByDate extends Component {
  constructor(props) {
    super(props);

    var dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 7);
    
    this.state = {
      dateEnd: new Date(),
      itemTitle: "",
      isLoading : false,
      rows: []
    };

    this.loadData = this.loadData.bind(this);
  }

  dateEndOnChange = dateEnd => this.setState({ dateEnd })

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }
  componentDidMount() {
    this.loadData();
  }

  loadData () {
    this.setState({ isLoading: true });
    var self = this;
    
      fetch('api/report/reportStockByDate?end=' + encodeURIComponent(formatDate(this.state.dateEnd)) + "&itemTitle=" + encodeURIComponent(this.state.itemTitle), getAuthToken())
    .then(function(response) {
      return response.json();
    }).then(function(data) {
      self.setState({ isLoading: false });
      if (data.success == true) {
        self.setState({ rows: calculateTotals (data.data) });
      } else {
          alert('Napaka');
      }
    }).catch(function (data) {
      self.setState({ isLoading: false });
      alert('Pri dostopu do strežnika je prišlo do napake. Prosim poskusite ponovno.');
    });
  }
  
  render() {
    const options = {
      noDataText: 'Ni podatkov'
    };

    return (
      <div  className="wrapper">
        <Loader show={this.state.isLoading} />
        <Navigation history={this.props.history} />
        <div className="page">
            
            <Header title="Poročilo zaloge na dan"/>

            <div className="page-content">
                <div className="content-sticky-footer">
                    
                  <div className="col-12">
                    <br/>
                    <div className="card rounded-0 border-0 mb-3 bg-primary">
                      <div className="card-body  text-white">
                          <div className="row">
                              
                          <div className="col-9">
                              <span>{formatDateToGrid(this.state.dateEnd)}</span>
                              <span>-</span>
                              <span>{this.state.itemTitle}</span>
                          </div>

                          <div className="col-3">
                            <a className="btn btn-block gradient border-0 z-3" 
                                href="javascript:void(0)" 
                                data-toggle="modal" 
                                data-target="#popupFilter"
                                >
                                <i className="material-icons">filter_list</i>
                            </a>
                          </div>
                            

                          </div>
                          
                      </div>
                    </div>

                  </div>

                  <div className="col-12">
                    <BootstrapTable data={this.state.rows} version='4'  options={ options } striped hover>
                      <TableHeaderColumn width='150' isKey={ true } dataField='id' hidden={true} dataFormat={ numberFormatter }>Šifra</TableHeaderColumn>
                      <TableHeaderColumn width='150' dataField='itemTitle' dataFormat={ stringFormatter }>Naziv</TableHeaderColumn>
                      <TableHeaderColumn width='150' dataField='quantity' dataFormat={ numberFormatter }>Količina</TableHeaderColumn>
                      <TableHeaderColumn width='150' dataField='warehouseTitle' dataFormat={ stringFormatter }>Skladišče</TableHeaderColumn>
                    </BootstrapTable>
                  </div>

                </div>
                <Footer/>
            </div>
        </div>
        <div className="modal fade" id="popupFilter" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="background bg-225"><img src="img/background.png" alt="" /></div>
                    <div className="modal-header">
                        <h5 className="text-white">Filter</h5>
                        <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        
                        <div className="row">
                            <div className="col mt-3">
                                <div className="login-input-content">

                                <div className="col-12">
                                  <br/>
                          
                                  <div className="col-12">
                                      <label>Datum</label>
                                  </div>
                                  <div className="col-12">
                                    <div className="form-group">
                                      <DateTimePicker 
                                      className="form-control  text-white" 
                                      format="dd.MM.yyyy" 
                                      onChange={this.dateEndOnChange}
                                      value={this.state.dateEnd}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-12">
                                      <label>Artikel</label>
                                  </div>
                                  <div className="col-12">
                                    <FormGroup controlId="itemTitle" className="input-group" >
                                      <FormControl
                                        type="text"
                                        value={this.state.itemTitle}
                                        onChange={this.handleChange}
                                      />
                                    </FormGroup>
                                  </div>
                                  <br/>
                                </div>

                                </div>
                                <div className="row mx-0 justify-content-end no-gutters">
                                    <div className="col">
                                        <br/>
                                        <a href="javascript:void(0)" onClick={this.loadData} className="btn btn-block gradient border-0 z-3" data-dismiss="modal">
                                          <i className="material-icons">search</i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

export default ReportStockByDate;